import * as Sentry from '@sentry/browser';
import { Microbranding } from '../../reducers/microbranding-reducer';
import { currentEnv, isFedM } from '../env';
import { isMobileOidc } from '../oidc/oidc';

type SentryPayload = Record<string, unknown> | Error;

// Only turn on debug for non production and non test runs (i.e. Jest);
const debug = currentEnv !== 'prod' && process.env.NODE_ENV !== 'test';

// https://hello-atlassian-net.jira.btpn.skyfencenet.com/wiki/spaces/OBSERVABILITY/pages/3370131692/Sentry+FedRAMP+-+Getting+Started#Sentry-DSN-in-FedRAMP
const fedMSentryDSN = 'https://1c864dd6ac671ce6296e7cc6710a21a0@sentry.atlassian-us-gov-mod.com/22';
const commercialSentryDSN =
  'https://1271fcf5ddad45a1846153219eb726bd@o55978-ingest-sentry-io.jira.btpn.skyfencenet.com/4504172801622016';

Sentry.init({
  dsn: isFedM() ? fedMSentryDSN : commercialSentryDSN,
  debug,
  environment: currentEnv,
  release: process.env.REACT_APP_VERSION || 'no-version',
  enabled: currentEnv !== 'local',
  sampleRate: 0.1,
  beforeSend(event) {
    // Remove URL queryparams, they can contain PII
    if (event.request?.url) {
      event.request.url = event.request.url.split('?')[0];
      delete event.request.query_string;
    }

    return event;
  },
  beforeBreadcrumb(breadcrumb) {
    // Don't send breadcrumbs that may contain PII
    if (['navigation', 'fetch', 'xhr'].includes(breadcrumb.category || '')) {
      return null;
    }

    return breadcrumb;
  },
  denyUrls: [
    /^chrome:\/\//,
    /^chrome-extension:\/\//,
    /accounts\.google\.com/, // Google OneTap
    /recaptcha-net.jira.btpn.skyfencenet.com/, // Google ReCAPTCHA
  ],
  ignoreErrors: [
    /^null/,
    /^undefined/,
    /Loading chunk.\d+.failed/,
    /^[^.]+\w+ is not defined/, // Ignore injected globals
    'Load Failed',
    'NetworkError',
    'Failed to fetch',
    'SecurityError', // Incognito errors
    'Object Not Found Matching Id', // Selenium webdriver
    'Non-Error promise rejection captured with value:',
    'ResizeObserver loop limit exceeded', // From browser extensions, ResizeObserver isn't used
  ],
});

export function initClient(microbranding: Microbranding) {
  Sentry.configureScope(scope => {
    // We're not going to send all of microbranding just in case someone
    // puts PII into microbranding later on
    scope.setExtra('isEmbedded', microbranding.isEmbedded);
    scope.setExtra('isMobileOidc', isMobileOidc(microbranding.oidcContext));
  });
}

export function setUserId(userId: string) {
  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });
  });
}

export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  if (!breadcrumb.level) {
    breadcrumb.level = 'info';
  }
  Sentry.addBreadcrumb(breadcrumb);
}

export function setLocale(locale: string | undefined) {
  if (locale) {
    Sentry.configureScope(scope => {
      scope.setTag('locale', locale);
    });
  }
}

/**
 * There are Sentry alerts configured based on these tags. Take care to update the configuration of
 * these alerts when changing tag values. Adding new tag values is safe.
 */
export type RecaptchaTag = 'no_token_score' | 'no_token_checkbox';

/**
 * Tags allow adding searchable custom data to events.
 *
 * This is useful in general for making events searchable by some custom piece of data, and for
 * configuring filters for alerts.
 *
 * See:
 * https://docs-sentry-io.jira.btpn.skyfencenet.com/platforms/javascript/enriching-events/tags/
 * https://github.com/getsentry/support/discussions/50#discussioncomment-3263832
 */
export type SentryTags = {
  recaptcha?: RecaptchaTag;
  statsigFeatureGate?: 'statsig_feature_gate_client_init';
};

export function captureException(ex: Error, payload: SentryPayload = {}, tags?: SentryTags) {
  if (payload) {
    Sentry.withScope(scope => {
      Object.entries(payload).forEach(([key, value]) => {
        scope.setExtra(key, value);
      });

      if (tags) {
        Sentry.setTags(tags);
      }

      Sentry.captureException(ex);
    });
  } else {
    Sentry.captureException(ex);
  }
}

export const captureRecaptchaException = (
  ex: Error,
  payload: SentryPayload = {},
  tag: RecaptchaTag
) => {
  captureException(ex, payload, {
    recaptcha: tag,
  });
};

export function captureErrorMessage(msg: string, payload: SentryPayload = {}) {
  captureMessage(msg, payload, 'error');
}

export const captureRecaptchaMessage = (
  msg: string,
  payload: SentryPayload = {},
  tag: RecaptchaTag
) => {
  captureMessage(msg, payload, 'error', {
    recaptcha: tag,
  });
};

export function captureMessage(
  msg: string,
  payload: SentryPayload,
  severity: Sentry.SeverityLevel,
  tags?: SentryTags
) {
  if (payload) {
    Sentry.withScope(scope => {
      Object.entries(payload).forEach(([key, value]) => {
        scope.setExtra(key, value);
      });

      if (tags) {
        Sentry.setTags(tags);
      }

      Sentry.captureMessage(msg, severity);
    });
  } else {
    Sentry.captureMessage(msg, severity);
  }
}
